import ApiService from '@/services/ApiService';

const API_URL = '/api/Admin/PlayroomRating/';

class AdminPlayroomRatingService {
  unlockPlayroomRating(playroomRatingId: string) {
    return ApiService.post(`${API_URL}Unlock`, { playroomRatingId });
  }

  getIndex(currentPage: number, sortColumn: string, sortAsc: boolean, tableFilter: string) {
    return ApiService.get(
      `${API_URL}Index?currentPage=${currentPage}&sortColumn=${sortColumn}&sortAsc=${sortAsc}&tableFilter=${tableFilter}`
    );
  }
}

export default new AdminPlayroomRatingService();
