


























































































import Vue from 'vue';
import AdminPlayroomRatingService from '@/services/admin/AdminPlayroomRatingService';
import FormatedTimestamp from '@/components/shared/FormatedTimestamp.vue';
import DataTable from '@/components/shared/DataTable.vue';
import { validationMixin } from 'vuelidate';
import { MetaInfo } from 'vue-meta';
import ToastMessage from '@/models/ToastMessage';

class PlayroomRating {
  id!: string;
  createdAt!: string;
  message!: string;
  rating!: string;
  playroomId!: string;
  playroomName!: string;
  deletedAt!: string;
}

export default Vue.extend({
  mixins: [validationMixin],
  components: {
    FormatedTimestamp,
    DataTable,
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.admin.AdminPlayroomRatingsIndex.meta.title').toString(),
    };
  },
  data() {
    return {
      playroomRatings: [] = new Array<PlayroomRating>(),
      tableFilterValue: '',
      pages: 0,
      sorterValue: { column: 'createdAt', asc: false },
      currentPage: 1,
      loading: false,
      ready: false,
      message: '',
      showModal: false,
      modalPlayroomRating: new PlayroomRating(),
    };
  },
  watch: {
    sorterValue: function () {
      this.loadData();
    },
    currentPage: function () {
      this.loadData();
    },
    tableFilterValue: function () {
      this.loadData();
    },
  },
  computed: {},
  methods: {
    loadData() {
      this.loading = true;
      AdminPlayroomRatingService.getIndex(
        this.currentPage,
        this.sorterValue.column,
        this.sorterValue.asc,
        this.tableFilterValue
      ).then((res) => {
        this.loading = false;
        this.ready = true;
        this.playroomRatings = res.value.ratings.items.map((t: PlayroomRating) => ({
          ...t,
        }));
        this.pages = res.value.ratings.totalPages;
      });
    },

    unlock() {
      this.loading = true;
      AdminPlayroomRatingService.unlockPlayroomRating(this.modalPlayroomRating.id).then(
        () => {
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('forms.common.save_successful').toString(), 'bg-success')
          );
          this.loading = false;
          this.showModal = false;
          this.loadData();
        },
        (error) => {
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('forms.common.save_error').toString(), 'bg-warning')
          );
          this.loading = false;
          this.message = error;
        }
      );
    },

    setBreadcrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.admin.AdminPlayroomRatingsIndex.breadcrumb.last' },
      ]);
    },

    openPlayroomRating(playroomRating: PlayroomRating) {
      this.showModal = true;
      this.modalPlayroomRating = playroomRating;
    },
  },
  mounted() {
    this.loadData();
    this.setBreadcrumb();
  },
});
